@import "../utilities/variables";

/* ====================== */
/* BASE STYLLES */
/* ====================== */
* {
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  padding: 0;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  min-height: 1280px;
  color: #ffffff;
  font-size: 24px;
  background-color: $bg-color-right;
}

p.intro-text {
  font-size: 1.2rem;
  text-align: left;
  font-family: $main-font;
  color: white;
  max-width: 100%;
  margin: auto;
}

p,
h1,
h2,
h3,
h4,
a {
  margin: 0;
  font-weight: 400;
}

a:hover {
  color: $squid-ink;
}

a {
  color: $squid-ink;
  text-decoration: underline;
}

#intro {
  max-width: 45rem;
  margin: 1rem auto;
  text-align: center;
}

.cardSpan {
  cursor: pointer;
}

#toc {
  padding-left: 4rem;
}

#intro__icon h2 {
  font-family: AmazonEmberDisplayHeavy;
  font-size: 1rem;
}

.text {
  h2 {
    font-size: 1.5rem;
    padding-left: 1px;
    font-family: AmazonEmberDisplayHeavy;
  }
}

.icon,
.text {
  vertical-align: middle;
  display: inline-block;
}

/* ====================== */
/* LAYOUT STYLES */
/* ====================== */

#ai {
  color: $ai-color;
  font-family: AmazonEmberDisplayHeavy;
}

.logo {
  color: $color-left;
  width: 10rem;
}

#intro-icon {
  display: flex;
  text-transform: uppercase;
  font-weight: bold;
  color: $color-left;
  flex-direction: row;
}

#intro-icon h2 {
  padding-left: 2%;
  font-family: AmazonEmberDisplayHeavy;
  font-size: 1.2rem;
  margin-top: 10px;
  margin-bottom: 10px;
}

.section-title {
  font-family: AmazonEmberDisplayHeavy;
  font-size: 2.2rem;
  text-align: center;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

section {
  min-height: 100vh;
  vertical-align: middle;
  text-align: center;
  align-items: center;
  justify-content: center;
}

section {
  // margin-bottom: 24vh;

  h2 {
    padding-top: 20vh;
    font-size: 2.5rem;
    color: $color-left;
    font-family: $font-heavy;
  }

  h3 {
    font-size: 1.5rem;
    padding-top: 1rem;
    font-family: AmazonEmberDisplayHeavy;
    color: $color-left;
    opacity: 0.9;
  }
}

section p {
  text-align: center;
  justify-content: center;
  align-items: middle;
  vertical-align: middle;
  text-align: center;
  align-items: center;
  justify-content: center;
  text-align: left;
}

#intro-icon svg {
  color: black;
  fill: black;
}

#scrolly>* {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

#scrolly {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

article {
  position: relative;
  padding: 0 1.5rem;
  vertical-align: middle;
  text-align: center;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  margin: 0 auto;
  max-width: 34%;
  color: $color-left;
  background-color: $bg-color-left;

  p {
    font-size: 1.2rem;
    padding-top: 2rem;
    justify-content: center;
    text-align: center;
    background-color: $bg-color-left;
  }

  h2 {
    background-color: $bg-color-left;
    font-family: $font-heavy;
    padding: 0px;
    margin: 0px;
    color: $color-left;
    text-align: left;
  }

  h3 {
    display: none;
  }

  #end-p {
    display: none;
  }
}

figure {
  position: -webkit-sticky;
  position: sticky;
  height: 90vh;
  width: 100%;
  margin: 0;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  z-index: 0;
  vertical-align: center;
  top: 6rem;
  justify-content: center;
  text-align: center;
}

#all {
  margin-bottom: 15vh;
}

section {
  h2 {
    padding-top: 0vh;
  }
}

// new
#title {
  background-color: $bg-color-left;
  display: flex;
  align-items: center;
  text-align: center;
  flex-direction: column;
  min-width: 35%;
  min-height: 110vh;

  h1 {
    color: $color-left;
    font-family: $font-heavy;
    font-size: 5rem;
    padding: 1rem;
  }

  p {
    color: $color-left;
    font-family: $font-heavy;
    opacity: 0.9;
    font-size: 1.45rem;
  }

  a {
    color: $color-left;
    background-color: $bg-color-left;
    text-decoration: underline;
    font-size: 1.4rem;
  }

  a:hover {
    color: $squid-ink;
  }

  #subtitle {
    color: $color-left;
    font-family: $font-light;
    font-size: 1.4rem;
    padding: 1rem;
    opacity: 0.8;
  }
}

section#intro-text {
  font-size: 1rem;
  padding: 5rem;
  background-color: $bg-color-right; //$ai-color;
  min-height: 100vh;
  padding-top: 5rem;
  color: $color-right;

  h2 {
    font-size: 1.6rem;
    font-family: $font-light;
    padding-bottom: 1rem;
  }

  p {
    padding: 1rem;
    font-size: 1.2rem;
    max-width: 100%;
  }
}

#chart,
#chart2 {
  width: 100%;
  margin: 0 auto;
}

#chart-wrapper {
  // grid for plots
  display: grid;
  grid-template-columns: 40% 55%;
  grid-row-gap: 2rem;
  grid-column-gap: 1rem;
  grid-template-rows: 60% 40%;
  justify-content: center;
  height: 90vh;
}

#train {
  margin-bottom: 25vh;
}

// new
#main-intro {
  display: flex;
  text-align: center;
  min-height: 110vh;
}

#splits {
  text-align: center;
  padding: 5rem;
  background-color: $bg-color-left;
  color: $color-left;
  margin-bottom: 0;

  p {
    font-size: 1.2rem;
    margin: 0 auto;
  }
}

ol.numbered-list {
  font-size: 1.1rem;
  text-align: left;
  list-style-type: number;
  margin: 0 auto;
  width: 90%;
  font-family: $font-light;

  .katex {
    font-size: 1.1rem;
  }
}

li {
  margin: 10px 0;
}

#anotherlook {
  text-align: center;
  padding: 5rem;
  background-color: $bg-color-left;
  color: $color-left;
  margin-bottom: 0;

  p {
    // padding: 1rem;
    font-size: 1.2rem;
    margin: 0 auto;
  }
}

#limitations {
  text-align: center;
  padding: 5rem;
  background-color: $bg-color-left;
  color: $color-left;
  margin-bottom: 0;
  min-height: initial;
  padding-bottom: 2rem;

  h2 {
    font-size: 2.5rem;
    font-family: $font-light;
    padding-bottom: 1rem;
    color: $color-left;
  }
}

#final {
  text-align: center;
  padding: 5rem;
  background-color: $bg-color-left;
  margin-bottom: 0;
  min-height: initial;
  padding-top: 3rem;

  h3 {
    color: white;
  }

  h2 {
    color: white;
  }

  p {
    color: white;
  }

  hr {
    margin: auto;
    max-width: 20%;
    border: 1px solid white;
    border-radius: 5px;
  }
}

.boxed {
  border: 6px solid white;
  padding-bottom: 0.5rem;
  max-width: 50%;
  margin: 1rem auto;

  h3 {
    text-align: left;
    font-size: 1.1rem;
    padding-left: 2rem;
  }

  p {
    font-size: 20px;
    text-align: center;
    margin: 0 auto;
  }
}

// utils
.bold {
  text-align: left;
  font-family: $font-heavy;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

// mew

article {
  background-color: $bg-color-left;
}

.annotation-note {
  font-family: $font-mono;
  font-size: 0.9rem;
  fill: $color-right;
}

.annotation-note-label {
  font-family: $font-light;
  font-size: 0.9rem;
  fill: $color-right;
}

.annotation-note-title {
  font-family: $font-heavy;
  font-size: 1rem;
  fill: $color-right;
  font-weight: normal;
}

.connector {
  stroke-dasharray: (4, 4);
  stroke: white;
}

#intro-tree-chart {
  margin: auto;
  width: 100%;
}

article {
  section:nth-child(n + 2) {
    padding: 1rem;

    p {
      text-align: left;
    }
  }
}

section#intro-text,
#title-section {
  padding-bottom: 25rem; // distance below intro text - make responsive
}

.center-text {
  max-width: 60%;
  margin: auto;
  padding: 1rem;
}

p.center-text {
  font-size: 1.2rem;
}

a {
  // color: $squid-ink;
  color: white;
  text-decoration: underline;
}

a.on-end {
  // color: $squid-ink;
  color: white;
  text-decoration: underline;
  font-family: $font-light;
}

a.on-end:hover {
  color: $squid-ink;
}

p.resource-item {
  padding: 0.2rem 1rem;
  max-width: 60%;
  margin: auto;
  font-size: 1.1rem;
}

.reference {
  text-decoration: underline;
}

.intro-text-mobile {
  display: none;
}

// iPad
@media (max-width: 850px) {
  #intro-tree-chart {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .intro-text-desktop {
    display: none;
  }

  .intro-text-mobile {
    display: block;
  }

  #intro-text {
    padding: 0;
    max-width: 100%;
  }

  #title h1 {
    font-size: 2.7rem;
    padding: 2.5rem 1rem;
  }

  .logo {
    width: 6rem;
  }

  #title #subtitle {
    font-size: 1.3rem;
    padding: 1.8rem 0.4rem;
  }

  #title p,
  #title a {
    font-size: 1.2rem;
  }

  article section:nth-child(n + 2) {
    padding: 0.5rem;
  }

  section#intro-text p {
    font-size: 1.1rem;
    padding: 0px;
    padding-left: 2px;
  }

  section#intro-text {
    padding: 0.2rem;
  }

  article {
    padding: 0;
    margin: 0 auto;
    max-width: 38%;

    p {
      font-size: 1.2rem;
      padding-top: 0.2rem;
      justify-content: center;
      text-align: center;
    }

    h2 {
      font-size: 1.5rem;
      padding: 0px;
      margin: 0px;
      padding-bottom: 1rem;
    }

    h3 {
      display: none;
    }

    #end-p {
      display: none;
    }
  }

  figure {
    position: -webkit-sticky;
    position: sticky;
    height: 90vh;
    width: 100%;
    margin: 0;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    z-index: 0;
    vertical-align: center;
    top: 8vh;
    justify-content: center;
    text-align: center;
  }

  section {
    margin-bottom: 5vh;
  }

  .annotation-note {
    font-size: 0.8rem;
  }

  .annotation-note-label {
    font-size: 0.8rem;
  }

  .annotation-note-title {
    font-size: 0.85rem;
  }

  .center-text {
    max-width: 95%;
  }

  .boxed {
    border: 4px solid white;
    max-width: 95%;
  }

  ol.numbered-list {
    width: 80%;
  }

  #splits {
    text-align: center;
    padding: 0.5rem 0.1rem;
    margin-bottom: 0;
    padding-top: 4rem;

    p {
      font-size: 1.2rem;
      margin: 0 auto;
    }
  }

  #anotherlook,
  #limitations,
  #final {
    padding: 0.5rem;
  }

  p.resource-item {
    font-size: 1.2rem;
    padding: 0.5rem 1rem;
    max-width: 95%;
  }

  #final hr {
    max-width: 95%;
  }

  #chart-wrapper {
    // grid for plots
    display: grid;
    width: 100%;
    grid-template-columns: 100%;
    grid-row-gap: 2rem;
    grid-column-gap: 0rem;
    grid-template-rows: 55% 30%;
    height: 85vh;
  }

  #intro-icon h2 {
    padding-left: 0;
    font-size: 1rem;
  }

  .logo {
    width: 100%;
  }

  section#intro-text,
  #title-section {
    padding-bottom: 0rem; // distance below intro text - make responsive
  }

  #title {
    min-height: 90vh;
  }
}

// mobile
@media (max-width: 600px) {
  .intro-text-desktop {
    display: none;
  }

  .intro-text-mobile {
    display: block;
  }

  #intro-text {
    padding: 0;
    max-width: 100%;
  }

  #title h1 {
    font-size: 2.7rem;
    padding: 2.5rem 1rem;
  }

  .logo {
    width: 6rem;
  }

  #title #subtitle {
    font-size: 1.3rem;
    padding: 1.8rem 0.4rem;
  }

  #title p,
  #title a {
    font-size: 1.2rem;
  }

  article section:nth-child(n + 2) {
    padding: 0.5rem;
  }

  section {
    margin-bottom: 40vh;
  }

  section#intro-text p {
    font-size: 1.1rem;
    padding: 0px;
    padding-left: 2px;
  }

  section#intro-text {
    padding: 0.2rem;
  }

  article {
    padding: 0;
    margin: 0 auto;
    max-width: 50%;

    p {
      font-size: 1.2rem;
      padding-top: 0.2rem;
      justify-content: center;
      text-align: center;
    }

    h2 {
      font-size: 1.5rem;
      padding: 0px;
      margin: 0px;
      padding-bottom: 1rem;
    }

    h3 {
      display: none;
    }

    #end-p {
      display: none;
    }
  }

  figure {
    position: -webkit-sticky;
    position: sticky;
    height: 98vh;
    width: 100%;
    margin: 0;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    z-index: 0;
    vertical-align: center;
    top: 2vh;
    justify-content: center;
    text-align: center;
  }

  .annotation-note {
    font-size: 0.8rem;
  }

  .annotation-note-label {
    font-size: 0.8rem;
  }

  .annotation-note-title {
    font-size: 0.85rem;
  }

  .center-text {
    max-width: 95%;
  }

  .boxed {
    border: 4px solid white;
    max-width: 95%;
  }

  ol.numbered-list {
    width: 80%;
  }

  #splits {
    text-align: center;
    padding: 0.5rem 0.1rem;
    margin-bottom: 0;
    padding-top: 4rem;

    p {
      font-size: 1.2rem;
      margin: 0 auto;
    }
  }

  #anotherlook,
  #limitations,
  #final {
    padding: 0.5rem;
  }

  p.resource-item {
    font-size: 1.2rem;
    padding: 0.5rem 1rem;
    max-width: 95%;
  }

  #final hr {
    max-width: 95%;
  }

  #chart-wrapper {
    // grid for plots
    display: grid;
    width: 100%;
    grid-template-columns: 100%;
    grid-row-gap: 2rem;
    grid-column-gap: 0rem;
    grid-template-rows: 55% 30%;
    height: 85vh;
  }

  #intro-icon h2 {
    padding-left: 0;
    font-size: 1rem;
  }

  .logo {
    width: 100%;
  }

  section#intro-text,
  #title-section {
    padding-bottom: 0rem; // distance below intro text - make responsive
  }

  #title {
    min-height: 100vh;
  }
}


.start-button {
  position: absolute;
  left: 0 auto;
  right: 0 auto;
  top: 50%;
  font-size: 1.5rem;
  background: #53214c;
  color: #fcf6eb;
  padding: 1rem;
  border-radius: 10px;
  border: 1px solid #53214c;
  font-weight: 500;
}

.start-button:active {
  background-color: #441a3f;
}

.arrow-button {
  position: absolute;
  left: 0 auto;
  right: 0 auto;
  top: 65%;
  background: #53214c;
  color: #fcf6eb;
  padding: 1rem;
  border-radius: 100%;
  border: none;
  width: 4rem;
  height: 4rem;
}

.arrow-button:active {
  background-color: #53214c;
}

////////////////////////////
////////////////////////////
// Random Forest CSS
/////////////////////////////
////////////////////////////

#random-forest section {
  padding-bottom: 2rem;
  font-family: $main-font;
  min-height: 1280px;
  color: #ffffff;
  font-size: 20px;
  line-height: 1.6;
  background: #53214c;
  vertical-align: middle;
  text-align: center;
  align-items: center;
  justify-content: center;
  background: #53214c;
}

#random-forest h2 {
  padding: 2rem 0rem;
  color: #ffffff;
}

#random-forest section h2 {
  padding-top: 0vh;
}

#random-forest section h2 {
  padding-top: 20vh;
  font-size: 2.5rem;
  color: #ffffff;
  font-family: AmazonEmberDisplayHeavy;
}

#random-forest h2 {
  margin: 0;
  font-weight: 400;
}

#scrolly-rf > * {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;

  section {
    min-height: 100vh;
  }
}

#scrolly-rf {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  flex-direction: row-reverse;
}

@media screen and (min-width: 1500px) {
  #scrolly-condurcet,
  #scrolly-rf {
    margin: auto;
    max-width: 100%;
  }

  .one-column {
    p {
      max-width: 800px;
    }
  }

  #chart-wrapper-cantor {
    grid-template-columns: 50%;
    grid-template-rows: 55% 40%;
  }

  // barcode
  #barcode-chart {
    width: 70%;
    height: 85vh;
    min-height: 500px;
    margin: auto;
  }
}

@media screen and (max-width: 960px) {
  // condurcet updates
  text.percent {
    font-size: 0.3rem;
  }

  // make chart title smaller
  .scatter-axis-text {
    font-size: 1rem;
  }

  #chart-wrapper-condurcet {
    grid-template-columns: 85%;
    grid-column-gap: 2rem;
    grid-template-rows: 6% 44% 6% 44%;
    padding-right: 5%;
    height: 85vh;
    padding-top: 2%;

    .chart {
      margin-top: 5%;
      margin-bottom: 4%;
    }

    .chartControls {
      padding-bottom: 20px;
      margin-bottom: 20px;
      grid-template-columns: 48% 44%;
      grid-template-rows: minmax(45px, 5%);
      padding-left: 0px;
      margin-left: 0px;
    }

    .sliderText {
      font-size: 1rem;
      text-align: left;
    }
    .slider {
      text-align: right;
      margin-top: 10%;
      width: 100%;
      height: 30%;
    }

    .slider::-webkit-slider-thumb {
      width: 0.8vw;
      height: 3vh;
    }
    .axis {
      font-size: 0.8vw;
    }
  }

  article#article-condurcet {
    padding: 0 1.5rem;
    max-width: 50%; // size of text section
    padding-right: 15%;
    padding-left: 0%;
    margin-left: 0px;
  }

  figure#figure-condurcet {
    height: 90vh;
    width: 95%;
    top: 3rem;
  }

  section {
    h2 {
      padding-top: 0vh;
    }
  }
  // make scrolls longer
  #scrolly-rf {
    min-height: 130vh;
    padding: 1rem 1rem;
  }

  section#ensemble {
    #emStaticChart {
      width: 80%;
      height: 30%;
    }
  }

  article#article-rf {
    padding: 0 1.5rem;
    max-width: 45%; // size of text section
    padding-right: 0%;
    padding-left: 0%;
    margin-left: 30px;

    p {
      font-size: 1.2rem;
      padding: 1rem;
      margin: 0rem;
    }

    h3 {
      text-align: left;
      font-size: 1.3rem;
    }
  }

  figure#figure-rf {
    height: 80vh;
    width: 90%;
    top: 1.5rem;
    padding-left: 0.5%;
  }

  // =================
  // end rf updates
  // =================
}

@media screen and (max-width: 768px) {
  // update intro title
  #introduction h1 {
    padding: 0rem;
    max-width: none;
  }

  #intro-icon {
    padding-top: 1rem;
    h2 {
      font-size: 1.3rem;
    }
  }

  section#ensemble {
    #emStaticChart {
      width: 90%;
      height: 30%;
    }
  }

  section#random-forest {
    h2 {
      font-size: 2rem;
    }
  }

  // control center text
  .one-column {
    padding: 1rem 1rem;
    padding-bottom: 4rem;
    p {
      max-width: 90%;
      font-size: 1rem;
      text-align: left;
    }
    h2 {
      padding: 1rem 0.5rem;
      font-size: 2rem;
    }
  }

  // make resources section take full-width
  section#resources {
    padding: 2rem;

    p {
      font-size: 1rem;
    }

    h1 {
      padding-top: 1rem;
    }

    p.resource-item {
      padding-top: 1.1em;
    }
  }
  // end center text controls

  // =================
  // CONDURCET updates
  // =================

  // make chart title smaller
  .scatter-axis-text {
    font-size: 0.77rem;
  }

  // make scrolls longer
  #scrolly-condurcet section {
    min-height: 130vh;
  }

  #chart-wrapper-condurcet {
    grid-template-columns: 100%;
    grid-column-gap: 2rem;
    grid-template-rows: 10% 40% 10% 40%;
    padding: 2%;
    height: 90vh;

    .chart {
      margin-bottom: 4%;
    }

    #x-axis text,
    #y-axis text {
      font-size: 0.75rem;
    }

    .chartControls {
      padding-bottom: 12px;
      margin-bottom: 12px;
      grid-template-columns: 90%;
      grid-template-rows: 50% 50%;
      padding-left: 0px;
      margin-left: 0px;
    }

    .sliderText {
      font-size: 1rem;
      text-align: left;
    }
    .slider {
      text-align: right;
      margin-top: 10%;
      width: 100%;
      height: 2vh;
    }

    .slider::-webkit-slider-thumb {
      width: 3vw;
      height: 3vh;
    }
    .axis {
      font-size: 0.8vw;
    }
  }

  article#article-condurcet {
    padding: 0 1.5rem;
    max-width: 50%; // size of text section
    padding-right: 2%;
    padding-left: 0%;
    margin-left: 0px;

    p {
      font-size: 1rem;
    }
  }

  figure#figure-condurcet {
    height: 90vh;
    width: 95%;
    top: 1.5rem;
    padding-left: 5%;
  }

  // =================
  // end CONDURCET updates
  // =================

  // =================
  // rf updates
  // =================

  // make scrolls longer
  #scrolly-rf {
    min-height: 130vh;
    padding: 1rem 1rem;
  }

  article#article-rf {
    padding: 0 1.5rem;
    max-width: 45%; // size of text section
    padding-right: 0%;
    padding-left: 0%;
    margin-left: 0px;

    p {
      font-size: 1rem;
      padding: 0rem;
      margin: 0rem;
    }

    h3 {
      text-align: left;
      font-size: 1.2rem;
    }
  }

  figure#figure-rf {
    height: 75vh;
    width: 98%;
    top: 1.5rem;
    padding-left: 0.5%;
  }

  // =================
  // end rf updates
  // =================

  .tree-text {
    stroke-width: 4px;
    font-size: 1rem;
  }

  #rf-text {
    letter-spacing: 7px;
    font-size: 1.3rem;
    stroke-width: 5px;
  }

  .rf-circle-text,
  .bar-circle-text {
    font-size: 1rem;
    stroke-width: 1.5px;
  }

  #barcode-chart {
    width: 100%;
    height: 100vh;
  }
  #cantor-treegrid {
    width: 70%;
    height: 50vh;
  }

  section {
    h2 {
      padding-top: 0vh;
    }
  }
}

@media screen and (max-width: 500px) {
  #introduction h1 {
    padding: 0rem;
    max-width: none;
  }

  #intro-icon {
    padding-top: 1rem;
    h2 {
      font-size: 1.3rem;
    }
  }

  // control center text
  .one-column {
    padding: 1rem 1rem;
    padding-bottom: 4rem;
    p {
      max-width: 90%;
      font-size: 1rem;
      text-align: left;
    }
    h2 {
      padding-top: 1rem;
      padding-left: 0.5rem;
      padding-right: 0.5rem;
      font-size: 2rem;
    }
  }

  section#ensemble {
    #emStaticChart {
      width: 100%;
      height: 30%;
    }
  }

  // make resources section take full-width
  section#resources {
    padding: 2rem;

    p {
      font-size: 1rem;
    }

    h1 {
      padding-top: 1rem;
    }

    p.resource-item {
      padding-top: 1.1em;
    }
  }
  // end center text controls

  // =================
  // CONDURCET updates
  // =================

  // make chart title smaller
  .scatter-axis-text {
    font-size: 0.67rem;
  }

  // make scrolls longer
  #scrolly-condurcet section {
    min-height: 130vh;
  }

  #chart-wrapper-condurcet {
    grid-template-columns: 100%;
    grid-column-gap: 2rem;
    grid-template-rows: 10% 40% 10% 40%;
    padding: 2%;
    height: 90vh;

    .chart {
      margin-bottom: 4%;
    }

    #x-axis text,
    #y-axis text {
      font-size: 0.75rem;
    }

    .chartControls {
      padding-bottom: 12px;
      margin-bottom: 12px;
      grid-template-columns: 90%;
      grid-template-rows: 50% 50%;
      padding-left: 0px;
      margin-left: 0px;
    }

    .sliderText {
      font-size: 0.8rem;
      text-align: left;
    }
    .slider {
      text-align: right;
      margin-top: 10%;
      width: 100%;
      height: 2vh;
    }

    .slider::-webkit-slider-thumb {
      width: 3vw;
      height: 3vh;
    }
    .axis {
      font-size: 0.8vw;
    }
  }

  article#article-condurcet {
    padding: 0 1.5rem;
    max-width: 50%; // size of text section
    padding-right: 2%;
    padding-left: 0%;
    margin-left: 0px;

    p {
      font-size: 1rem;
    }
  }

  figure#figure-condurcet {
    height: 90vh;
    width: 95%;
    top: 1.5rem;
    padding-left: 5%;
  }

  // =================
  // end CONDURCET updates
  // =================

  // =================
  // rf updates
  // =================

  // make scrolls longer
  #scrolly-rf {
    min-height: 130vh;
    padding: 1rem 1rem;
  }

  article#article-rf {
    padding: 0 1.5rem;
    max-width: 45%; // size of text section
    padding-right: 0%;
    padding-left: 0%;
    margin-left: 0px;

    p {
      font-size: 1rem;
      padding: 0rem;
      margin: 0rem;
    }

    h3 {
      text-align: left;
      font-size: 1.2rem;
    }
  }

  figure#figure-rf {
    height: 70vh;
    width: 98%;
    top: 1.5rem;
    padding-left: 0.5%;
    padding-top: 40%;
    // Make annotation smaller
    .annotation-note-label {
      font-size: 0.6rem;
    }
    .annotation-note-title {
      font-size: 0.8rem;
    }
    .voteText {
      font-size: 0.9rem;
    }

    svg#chart-rf {
      height: 90%;
    }
    path.route {
      stroke: #fcff00;
      stroke-width: 2px;
    }
  }

  #clickme-text {
    font-size: 0.6rem;
  }

  // =================
  // end rf updates
  // =================

  //  jellyfish
  .tree-text {
    stroke-width: 4px;
    font-size: 0.8rem;
  }

  #rf-text {
    letter-spacing: 7px;
    font-size: 1.3rem;
    stroke-width: 5px;
  }

  .rf-circle-text,
  .bar-circle-text {
    font-size: 0.85rem;
    stroke-width: 1.5px;
  }

  //  CANTOR
  #chart-wrapper-cantor {
    grid-template-columns: 100%;
    grid-template-rows: 55% 33%;
    justify-content: center;
    text-align: center;
    justify-items: center;
    height: 95vh;
    padding-top: 2%;

    .chart {
      margin-top: 1%;
      margin-bottom: 1%;
      position: relative;
    }
  }
  #cantor-treegrid {
    width: 60%;
  }
  #cantor-scatter {
    width: 85%;
    height: 100%; //10% more for the room for text at the bottom
  }
  #cantor-treegrid {
    width: 100%;
    height: 90%;
  }

  .legend-text,
  .cantor-label-text {
    font-size: 0.55rem;
    font-family: $font-light;
  }

  section {
    h2 {
      padding-top: 0vh;
    }
  }
}

article#article-rf {
  position: relative;
  padding: 0 1.5rem;
  vertical-align: middle;
  text-align: center;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  margin: 0 auto;
  max-width: 34%; // size of text section
  color: $color-left;
  background-color: #53214c;

  p {
    font-size: 1.15rem;
    margin: 4px;
    padding: 1rem;
    padding-top: 2rem;
    justify-content: center;
    text-align: left;
    background-color: #53214c;
  }

  .bold {
    font-weight: bold;
  }

  h2 {
    background-color: #53214c;
    font-family: AmazonEmberDisplayHeavy;
    display: inline-block;
    padding: 0px;
    margin: 0px;
    color: #ffffff;
  }

  h3 {
    background-color: #53214c;
    font-family: AmazonEmberDisplayHeavy;
    display: inline-block;
    padding: 0px;
    margin: 0px;
    color: #ffffff;
  }

  #end-p {
    display: none;
  }
}

figure#figure-rf {
  position: -webkit-sticky;
  position: sticky;
  height: 90vh;
  width: 100%;
  margin: 0;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  z-index: 0;
  vertical-align: center;
  // top: 6rem;
  justify-content: center;
  text-align: center;

  svg#chart-rf {
    width: 90%;
    height: 88%;
  }

  path.route {
    stroke: #fcff00;
    stroke-width: 5px;
  }

  text.tooltip {
    font-size: 0.9rem;
    paint-order: stroke;
    stroke: white;
    stroke-width: 1px;
    stroke-linecap: butt;
    stroke-linejoin: miter;
  }
}

.down-arrow {
  position: absolute;
  bottom: 10%;
  right: 3%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  border: 2px solid #fff;
  border-radius: 100%;
  height: 2.5rem;
  width: 2.5rem;
}