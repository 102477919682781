// colors
$squid-ink: #232f3e;
$ai_color: #e7dcca; // rgb(246, 233, 218); //rgb(154, 222, 222);

// font-colors
$color-left: white;
$color-right: white;
$color-bottom: black;

// background colors
$bg-color-left: #53214c; // #FB9794
$bg-color-right: #d698cd; // BC6FB1

$ig-color-l: rgb(0, 202, 202);
$ig-color-r: deeppink;
$ig-color: black;

// Fonts
$main-font: AmazonEmber;
$font-heavy: AmazonEmberDisplayHeavy;
$font-light: AmazonEmberDisplayLight;
$font-mono: AmazonEmberMono;

// Line-Height
$base-line-height: 1.5;
$header-line-height: 1.25;
